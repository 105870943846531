//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-778:_6968,_2980,_4308,_3479,_2518,_8928,_1120,_9528;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-778')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-778', "_6968,_2980,_4308,_3479,_2518,_8928,_1120,_9528");
        }
      }catch (ex) {
        console.error(ex);
      }